<template>
  <div class="tile is-ancestor is-parent">
    <div class="tile is-vertical is-half">
      <div class="tile is-parent">
        <article class="tile is-child box detail-page-tile">
          <div class="columns">
            <div class="column">
              <p class="title">Excess Invoice</p>
            </div>
            <div class="column is-narrow">
              <div v-if="!isLoadingInvoice"
                class="buttons">
                <a v-show="$user.hasEdit($route.meta.id) && !readOnlyView && !isUpdateExcessInvoice && invoice && !invoice.gstPaidDate"
                  class="button tooltip"
                  data-tooltip="Update Invoice"
                  @click="editInvoice()">
                  <span class="icon has-text-primary">
                    <i class="mdi mdi-pencil mdi-24px" />
                  </span>
                </a>
                <a v-show="$user.hasAdd($route.meta.id) && !readOnlyView && !invoice"
                  class="button tooltip"
                  data-tooltip="New Invoice"
                  @click="addInvoice()">
                  <span class="icon has-text-success">
                    <i class="mdi mdi-plus mdi-24px" />
                  </span>
                </a>
                <a v-show="$user.hasDelete($route.meta.id) && !readOnlyView && !isUpdateExcessInvoice && invoice && !invoice.gstPaidDate || (invoice && invoice.isNew)"
                  class="button tooltip"
                  data-tooltip="Delete Invoice"
                  @click="deleteInvoice(invoice.invoiceType)">
                  <span class="icon has-text-danger">
                    <i class="mdi mdi-delete mdi-24px" />
                  </span>
                </a>
                <a v-show="$user.hasEdit($route.meta.id) && isUpdateExcessInvoice && invoice && !invoice.isNew"
                  class="button tooltip"
                  data-tooltip="Update from quote"
                  @click="updateInvoice()">
                  <span class="icon has-text-primary">
                    <i class="mdi mdi-refresh mdi-24px" />
                  </span>
                </a>
                <a v-show="$user.hasEdit($route.meta.id) && isUpdateExcessInvoice && invoice && !invoice.isNew"
                  class="button tooltip"
                  data-tooltip="Cancel"
                  @click="cancelInvoice()">
                  <span class="icon has-text-warning">
                    <i class="mdi mdi-close mdi-24px" />
                  </span>
                </a>
              </div>
              <span v-if="invoice && invoice.gstPaidDate"
                class="tag is-warning is-pulled-right tooltip is-tooltip-warning"
                data-tooltip="GST has been paid">GST paid on {{ $filters.formatDateTimezone(invoice.gstPaidDate, $userInfo.locale) }}</span>
            </div>
          </div>
          <div v-if="!invoice && isLoadingInvoice"
            class="section">
            <span class="is-loading-image has-text-grey has-text-centered" />
          </div>
          <div v-else-if="invoice">
            <div class="field-body">
              <div class="field">
                <label class="label">Invoice No.</label>
                <div class="control">
                  <input class="input is-static"
                    :value="invoiceNoReference"
                    type="text"
                    placeholder="Invoice No."
                    readonly>
                </div>
              </div>
              <div v-if="!isUpdateExcessInvoice"
                class="field">
                <label class="label">Invoice Date</label>
                <input class="input is-static"
                  :value="$filters.formatDateTimezone(selectedInvoiceDate, $userInfo.locale)"
                  readonly>
              </div>
              <div v-else-if="$userInfo && !gstPaid && selectedInvoiceDate"
                class="field">
                <label class="label">Invoice Date</label>
                <v-date-picker v-model="selectedInvoiceDate"
                  :masks="formats"
                  :attributes="attrs"
                  :locale="$userInfo.locale"
                  :min-date="firstDayOfMonth"
                  @input="invoiceDateInputEvent"
                  :timezone="$filters.getIanaTimezone()">
                  <template v-slot="{ inputValue, inputEvents }">
                    <div class="field">
                      <div class="control has-icons-left">
                        <input type="text"
                          class="input"
                          placeholder="Date To..."
                          :value="inputValue"
                          v-on="inputEvents"
                          readonly>
                        <span class="icon is-small is-left">
                          <i class="mdi mdi-calendar mdi-18px" />
                        </span>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>
            </div>
            <div class="is-divider" />
            <div v-if="!isUpdateExcessInvoice"
              class="field">
              <label class="label">Payer</label>
              <input class="input is-static"
                :value="invoice.assetName"
                type="text"
                readonly>
            </div>
            <div class="field">
              <div class="field-body">
                <div class="field">
                  <!-- <div class="field">
                    <div class="control is-pulled-right">
                      <input class="is-checkradio is-small is-circle is-primary is-rtl"
                        :class="{ 'has-background-color': excessWithGst }"
                        id="chk-excessWtihGst"
                        name="chk-excessWtihGst"
                        type="checkbox"
                        v-model="excessWithGst"
                        :disabled="!isUpdateExcessInvoice || hasQuoteInvoice">
                      <label for="chk-excessWtihGst">Excess with GST</label>
                    </div>
                  </div> -->
                  <div class="field">
                    <div class="is-flex is-justify-content-space-between">
                      <label class="label">Excess</label>
                      <div class="is-flex is-align-items-center is-justify-content-end mb-2">
                        <label class="pretty-checkbox-label pr-1 is-size-7"
                          for="chk-invoiceExcessWithGst">Excess with GST</label>
                        <div class="pretty p-icon p-round p-smooth m-0">
                          <input id="chk-invoiceExcessWithGst"
                            type="checkbox"
                            v-model="excessWithGst"
                            :disabled="!isUpdateExcessInvoice || hasQuoteInvoice">
                          <div class="state p-primary">
                            <i class="icon mdi mdi-check" />
                            <label />
                          </div>
                        </div>
                      </div>
                    </div>
                    <vue-numeric class="input has-text-right"
                      v-model.number="invoice.excessAmount"
                      :min:="0"
                      :precision="2"
                      :disabled="!isUpdateExcessInvoice || hasQuoteInvoice"
                      @input="updateTotal()" />
                  </div>
                </div>
                <div v-if="!hasOcInvoice"
                  class="field">
                  <label class="label">Owner Contribution</label>
                  <vue-numeric class="input has-text-right"
                    v-model.number="ownersContribution"
                    :min:="0"
                    :precision="2"
                    :disabled="!isUpdateExcessInvoice || hasQuoteInvoice"
                    @input="updateTotal()"
                    @blur="onBlurOwnersContribution()" />
                </div>
              </div>
            </div>
            <div v-if="!hasOcInvoice"
              class="field">
              <div class="field-body">
                <div class="field">
                  <label class="label">Extra Charge</label>
                  <vue-numeric class="input has-text-right"
                    v-model.number="extraCharge"
                    :min:="0"
                    :precision="2"
                    :disabled="!isUpdateExcessInvoice"
                    @input="updateTotal()"
                    @blur="onBlurExtraCharge()" />
                </div>
                <div class="field">
                  <label class="label">GST</label>
                  <vue-numeric class="input has-text-right"
                    v-model.number="invoice.gst"
                    :min:="0"
                    :precision="2"
                    :disabled="!isUpdateExcessInvoice"
                    @input="updateTotal(false)" />
                </div>
              </div>
            </div>
            <div class="field">
              <label class="label">Remarks</label>
              <textarea class="textarea"
                v-model="invoice.invoiceRemark"
                placeholder=""
                :disabled="!isUpdateExcessInvoice"
                rows="3" />
            </div>
            <div class="field">
              <div class="control">
                <input type="checkbox"
                  v-model="invoice.exported"
                  class="is-checkradio"
                  id="invoice-exported"
                  :disabled="readOnlyView || !$userInfo.isSupportUser || !isUpdateExcessInvoice">
                <label for="invoice-exported">Exported</label>
              </div>
            </div>
            <div class="field">
              <label class="label">Total Excl GST: <span class="has-text-success">{{ totalExGst | formatCurrency($userInfo.locale) }}</span></label>
            </div>
            <div class="field">
              <label class="label">Total Payable Incl GST: <span class="has-text-success">{{ totalPayableIncGst | formatCurrency($userInfo.locale) }}</span></label>
            </div>
          </div>
        </article>
      </div>
    </div>
    <div v-if="hasOcInvoice"
      class="tile is-parent is-half">
      <article class="tile is-child box detail-page-tile">
        <div class="columns">
          <div class="column">
            <p class="title">Owner's Contribution Invoice</p>
          </div>

          <div class="column is-narrow">
            <div v-if="!isLoadingInvoice"
              class="buttons">
              <a v-show="$user.hasEdit($route.meta.id) && !readOnlyView && !isUpdateExcessInvoice && ocInvoice && !ocInvoice.gstPaidDate"
                class="button tooltip"
                data-tooltip="Update Invoice"
                @click="editInvoice()">
                <span class="icon has-text-primary">
                  <i class="mdi mdi-pencil mdi-24px" />
                </span>
              </a>
              <a v-show="$user.hasAdd($route.meta.id) && !readOnlyView && !ocInvoice"
                class="button tooltip"
                data-tooltip="New Invoice"
                @click="addInvoice()">
                <span class="icon has-text-success">
                  <i class="mdi mdi-plus mdi-24px" />
                </span>
              </a>
              <a v-show="$user.hasDelete($route.meta.id) && !readOnlyView && !isUpdateExcessInvoice && ocInvoice && !ocInvoice.gstPaidDate || (ocInvoice && ocInvoice.isNew)"
                class="button tooltip"
                data-tooltip="Delete Invoice"
                @click="deleteInvoice(ocInvoice.invoiceType)">
                <span class="icon has-text-danger">
                  <i class="mdi mdi-delete mdi-24px" />
                </span>
              </a>
              <a v-show="$user.hasEdit($route.meta.id) && isUpdateExcessInvoice && ocInvoice && !ocInvoice.isNew"
                class="button tooltip"
                data-tooltip="Update from quote"
                @click="updateInvoice()">
                <span class="icon has-text-primary">
                  <i class="mdi mdi-refresh mdi-24px" />
                </span>
              </a>
              <a v-show="$user.hasEdit($route.meta.id) && isUpdateExcessInvoice && ocInvoice && !ocInvoice.isNew"
                class="button tooltip"
                data-tooltip="Cancel"
                @click="cancelInvoice()">
                <span class="icon has-text-warning">
                  <i class="mdi mdi-close mdi-24px" />
                </span>
              </a>
            </div>
            <span v-if="ocInvoice && ocInvoice.gstPaidDate"
              class="tag is-warning is-pulled-right tooltip is-tooltip-warning"
              data-tooltip="GST has been paid">GST paid on {{ $filters.formatDateTimezone(ocInvoice.gstPaidDate, $userInfo.locale) }}</span>
          </div>
        </div>
        <div v-if="ocInvoice">
          <div class="field-body">
            <div class="field">
              <label class="label">Invoice No.</label>
              <div class="control">
                <input class="input is-static"
                  :value="ocInvoiceNoReference"
                  type="text"
                  placeholder="Invoice No."
                  readonly>
              </div>
            </div>
          </div>
          <div class="is-divider" />
          <div class="field">
            <label class="label">Owner Contribution</label>
            <vue-numeric class="input has-text-right"
              v-model.number="ownersContribution"
              :min:="0"
              :precision="2"
              :disabled="!isUpdateExcessInvoice || hasQuoteInvoice"
              @input="updateTotal()"
              @blur="onBlurOwnersContribution()" />
          </div>
          <div class="field">
            <div class="field-body">
              <div class="field">
                <label class="label">Extra Charge</label>
                <vue-numeric class="input has-text-right"
                  v-model.number="extraCharge"
                  :min:="0"
                  :precision="2"
                  :disabled="!isUpdateExcessInvoice"
                  @input="updateTotal()"
                  @blur="onBlurExtraCharge()" />
              </div>
              <div class="field">
                <label class="label">GST</label>
                <vue-numeric class="input has-text-right"
                  v-model.number="ocInvoice.gst"
                  :min:="0"
                  :precision="2"
                  :disabled="!isUpdateExcessInvoice"
                  @input="updateTotal(false)" />
              </div>
            </div>
          </div>
          <div class="field">
            <label class="label">Remarks</label>
            <textarea class="textarea"
              v-model="ocInvoice.invoiceRemark"
              placeholder=""
              :disabled="!isUpdateExcessInvoice"
              rows="3" />
          </div>
          <div v-if="$userInfo.isSupportUser && ocInvoice"
            class="field">
            <div class="control">
              <input type="checkbox"
                v-model="ocInvoice.exported"
                class="is-checkradio"
                id="ocInvoice-exported"
                :disabled="!isUpdateExcessInvoice">
              <label for="ocInvoice-exported">Exported</label>
            </div>
          </div>
          <div class="field">
            <label class="label">Total Excl GST: <span class="has-text-success">{{ ocInvoice.totalExGst | formatCurrency($userInfo.locale) }}</span></label>
          </div>
          <div class="field">
            <label class="label">Total Payable Incl GST: <span class="has-text-success">{{ ocInvoice.totalIncGst | formatCurrency($userInfo.locale) }}</span></label>
          </div>
        </div>
      </article>
    </div>
  </div>
</template>

<script>
import { DateTimeFiltersMixin, NumberFiltersMixin } from '@/components/mixins/filters'
import VueNumeric from '@/components/VueNumeric'
import { roundAwayFromZero } from '@/components/utils/AccountingFunctions'
import QuickInvoiceService from '@/views/quickinvoice/QuickInvoiceService.js'
import Guid from '@/components/Guid'
import _debounce from 'lodash.debounce'
import { QuoteInvoiceService } from './services'
import { AssetTypes, InvoiceTypes, InvoicePayerTypes, EventHubTypes } from '@/enums'
import QuoteRoutes from './route-types'
import DeepDiff from 'deep-diff'
import _cloneDeep from 'lodash/cloneDeep'
import QuoteInvoiceStoreMixin from './mixins/QuoteInvoiceStoreMixin'

export default {
  name: 'QuoteExcessInvoice',
  components: {
    VueNumeric
  },
  filters: {},
  mixins: [DateTimeFiltersMixin, NumberFiltersMixin, QuoteInvoiceStoreMixin],
  props: {
    value: null,
    readOnlyView: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      innerValue: this.value,
      gstPaid: false,
      excess: this.value.excess,
      ownersContribution: this.value.ownersContribution,
      discount: this.value.discount,
      // extraCharge: 0,
      gst: roundAwayFromZero((this.value.totalExGst * this.value.gstRate) / 100),
      excessWithGst: this.value.excessWithGst,
      overrides: {
        labourTotal: 0,
        rrTotal: 0,
        repairTotal: 0,
        rwaTotal: 0,
        paintTotal: 0,
        fqTotal: 0,
        crushTotal: 0,
        cdTotal: 0,
        mechTotal: 0,
        pdrRrTotal: 0,
        pdrReTotal: 0
      },
      payerType: InvoicePayerTypes.Insurer,
      selectedAsset: null,
      assets: [],
      isAssetLoading: false,
      assetFilter: {
        assetId: Guid.empty(),
        assetName: '',
        showCustomer: true,
        showInsurer: true,
        pageIndex: 1,
        pageSize: 50
      },
      invoice: null,
      tabInvoiceType: InvoiceTypes.Excess,
      isLoadingInvoice: false,
      ocInvoice: null,
      selectedInvoiceDate: null,
      formats: {
        title: 'MMMM YYYY',
        weekdays: 'W',
        navMonths: 'MMM',
        input: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        data: ['L', 'DD-MM-YYYY', 'DD/MM/YYYY'],
        dayPopover: 'L'
      },
      attrs: [
        {
          key: 'today',
          highlight: {
            backgroundColor: '#ff8080'
          },
          popover: {
            label: 'Today'
          },
          dates: new Date()
        }
      ]
    }
  },
  computed: {
    routeTypes() {
      return QuoteRoutes
    },
    invoiceTypes() {
      return InvoiceTypes
    },
    hasQuoteInvoice() {
      const index = this.innerValue.invoices.findIndex((i) => i.invoiceType === InvoiceTypes.Quote && !i.deleted)
      return index >= 0
    },
    invoicePayerTypes() {
      return InvoicePayerTypes
    },
    totalExGst() {
      return this.invoice.totalExGst
    },
    totalIncGst() {
      return this.invoice.totalIncGst
    },
    totalPayableIncGst() {
      return this.invoice.totalIncGst
    },
    showAsset() {
      return {
        customer: this.assetFilter.showCustomer,
        insurer: this.assetFilter.showInsurer
      }
    },
    invoiceNoReference() {
      return this.invoice ? `${this.invoice.prefix}${this.invoice.invoiceNo}${this.invoice.suffix}/${this.invoice.invoiceType}` : ''
    },
    ocInvoiceNoReference() {
      return this.ocInvoice ? `${this.ocInvoice.prefix}${this.ocInvoice.invoiceNo}${this.ocInvoice.suffix}/${this.ocInvoice.invoiceType}` : ''
    },
    isDirty() {
      const diff = DeepDiff.diff(this.baseInvoice, this.invoice)
      return diff
    },
    invoiceValues() {
      if (this.invoice) {
        return this.invoice.excessAmount + this.invoice.ownerContribution + this.invoice.extraCharge
      } else {
        return 0
      }
    },
    ocInvoiceValues() {
      if (this.ocInvoice) {
        return this.ocInvoice.ownerContribution + this.ocInvoice.extraCharge
      } else {
        return 0
      }
    },
    baseInvoice() {
      if (this.baseSnapshot.invoices) {
        return _cloneDeep(this.baseSnapshot.invoices.find((i) => i.invoiceType === InvoiceTypes.Excess && !i.deleted))
      }
    },
    baseOcInvoice() {
      if (this.baseSnapshot.invoices) {
        return _cloneDeep(this.baseSnapshot.invoices.find((i) => i.invoiceType === InvoiceTypes.OwnerContribution && !i.deleted))
      }
    },
    hasOcInvoice() {
      return this.ocInvoice !== null && this.ocInvoice !== undefined
    },
    firstDayOfMonth() {
      if (this.$company.setting.allowInvoicePreviousMonth) {
        return null
      }
      let invoiceDate = new Date()
      if (!this.invoice.isNew) {
        invoiceDate = new Date(`${this.invoice.invoiceDate}`)
        return this.$filters.firstDayOfMonth(invoiceDate.getFullYear(), invoiceDate.getMonth())
      } else {
        return this.$filters.firstDayOfMonth()
      }
    }
  },
  watch: {
    showAsset(newVal, oldVal) {
      if (!newVal.customer && !newVal.insurer) {
        this.assetFilter.showCustomer = !oldVal.customer
        this.assetFilter.showInsurer = !oldVal.insurer
      }
      this.getAssetDropdown()
    },
    invoiceValues(newVal, oldVal) {
      if (this.invoice && this.isUpdateExcessInvoice) {
        this.recalculateTotals()
      }
    },
    ocInvoiceValues(newVal, oldVal) {
      if (this.ocInvoice && this.isUpdateExcessInvoice) {
        this.ocInvoice.totalExGst = this.ocInvoice.ownerContribution + this.ocInvoice.extraCharge
        this.ocInvoice.gst = roundAwayFromZero((this.ocInvoice.totalExGst * this.ocInvoice.gstRate) / 100)
        this.ocInvoice.totalIncGst = this.ocInvoice.totalExGst + this.ocInvoice.gst
      }
    },
    'invoice.excessAmount': function (newVal, oldVal) {
      if (this.isUpdateExcessInvoice && newVal !== undefined) {
        this.innerValue.excess = newVal
      }
    },
    // 'invoice.ownerContribution': function(newVal, oldVal) {
    //   if (this.isUpdateExcessInvoice && newVal !== undefined) {
    //     this.innerValue.ownersContribution = newVal
    //   }
    // },
    'invoice.gst': function (newVal) {
      if (this.invoice && this.isUpdateExcessInvoice) {
        this.recalculateTotals(false)
      }
    },
    'ocInvoice.gst': function (newVal) {
      if (this.ocInvoice && this.isUpdateExcessInvoice) {
        this.ocInvoice.totalExGst = this.ocInvoice.ownerContribution + this.ocInvoice.extraCharge
        this.ocInvoice.totalIncGst = this.ocInvoice.totalExGst + this.ocInvoice.gst
      }
    },
    excessWithGst: async function (newVal, oldVal) {
      if (this.isUpdateExcessInvoice) {
        if (newVal) {
          this.deleteOcInvoice()
          this.ownersContribution = this.innerValue.ownersContribution
          this.invoice.extraCharge = this.innerValue.extraCharge
          this.invoice.ownerContribution = this.ownersContribution
          this.invoice.extraCharge = this.extraCharge
          this.invoice.totalExGst = this.invoice.excessAmount + this.invoice.ownerContribution + this.invoice.extraCharge
          this.invoice.gst = roundAwayFromZero(((this.invoice.excessAmount + this.invoice.ownerContribution) * this.innerValue.gstRate) / 100)
        } else {
          if (this.ownersContribution > 0 || this.extraCharge > 0) {
            await this.addOcInvoice(this.ownersContribution, this.extraCharge)
            this.invoice.gst = 0
          }
        }
        this.innerValue.excessWithGst = newVal
        this.getInvoice()
      }
    },
    selectedInvoiceDate: function (newVal, oldVal) {
      if (newVal) {
        this.invoice.invoiceDate = newVal.toISOString().split('.')[0] + 'Z'
        if (this.ocInvoice) {
          this.ocInvoice.invoiceDate = newVal.toISOString().split('.')[0] + 'Z'
        }
      } else {
        this.invoice.invoiceDate = null
        if (this.ocInvoice) {
          this.ocInvoice.invoiceDate = null
        }
      }
    }
  },
  created() {
    this.getInvoice()
  },
  mounted() {
    this.$eventHub.$on(EventHubTypes.EntitySaved, () => {
      this.reloadInvoice()
    })
  },
  beforeDestroy() {
    this.$eventHub.$off(EventHubTypes.EntitySaved)
  },
  methods: {
    async getInvoice(cancel = false, isDelete = false) {
      this.isLoadingInvoice = true
      const baseSnapshot = _cloneDeep(this.baseSnapshot)
      // this.baseInvoice = _cloneDeep(this.baseSnapshot.invoices.find(i => i.invoiceType === InvoiceTypes.Excess && !i.deleted))
      if (!cancel) {
        this.invoice = this.innerValue.invoices.find((i) => i.invoiceType === InvoiceTypes.Excess && !i.deleted)
        this.ocInvoice = this.innerValue.invoices.find((i) => i.invoiceType === InvoiceTypes.OwnerContribution && !i.deleted)
        if (this.ocInvoice) {
          this.extraCharge = this.ocInvoice.extraCharge
          this.ownersContribution = this.ocInvoice.ownerContribution
        }
      } else {
        this.innerValue.excess = baseSnapshot.excess
        this.innerValue.ownersContribution = baseSnapshot.ownersContribution
        this.innerValue.excessWithGst = baseSnapshot.excessWithGst
        this.excessWithGst = this.innerValue.excessWithGst
        if (this.ocInvoice) this.extraCharge = this.ocInvoice.extraCharge
        this.ownersContribution = this.innerValue.ownersContribution
        for (var key in this.baseInvoice) {
          if (this.baseInvoice.hasOwnProperty(key)) {
            this.invoice[key] = this.baseInvoice[key]
          }
        }
        // if (this.excessWithGst && this.ocInvoice) {
        //   this.deleteOcInvoice()
        // } else if (!this.ocInvoice && (this.ownersContribution || this.extraCharge)) {
        //   this.addOcInvoice(this.ownersContribution, this.extraCharge)
        // }
      }
      if (this.isUpdateExcessInvoice) {
        if (this.excessWithGst && this.ocInvoice) {
          this.deleteOcInvoice()
        } else if (!this.ocInvoice && !isDelete && (this.ownersContribution || this.extraCharge)) {
          this.addOcInvoice(this.ownersContribution, this.extraCharge)
        }
      }
      if (this.invoice) {
        this.selectedInvoiceDate = new Date(`${this.invoice.invoiceDate}`)
      }
      this.isLoadingInvoice = false
    },
    async getAssetDropdown() {
      this.isAssetLoading = true
      this.assets = await QuickInvoiceService.getAssetDropdownProto(this.assetFilter)
      if (this.assetFilter.assetId !== Guid.empty()) {
        this.selectedAsset = this.assets.find((c) => c.assetId === this.selectedAsset.assetId)
      }
      this.isAssetLoading = false
    },
    getAssetDropdownFilter: _debounce(async function (query) {
      this.isAssetLoading = true
      this.assetFilter.assetName = query
      this.assets = await QuickInvoiceService.getAssetDropdownProto(this.assetFilter)
      this.isAssetLoading = false
    }, 500),
    async selectAsset(selected) {
      // this.value.assetId = selected.assetId
      // this.value.assetType = selected.assetType
      // this.value.invoiceGlCode = selected.assetGlCode
      this.assetFilter.assetId = selected.assetId
    },
    removeAsset(removed) {
      this.assetFilter.assetId = Guid.empty()
      // this.value.assetId = Guid.empty()
      // this.value.contactId = Guid.empty()
      // this.value.assetType = null
      this.getAssetDropdown()
    },
    editInvoice() {
      this.updateInvoice()
      // this.isUpdateExcessInvoice = true
      this.setIsUpdateExcessInvoice(true)
      if (this.isUpdateExcessInvoice) {
        if (this.excessWithGst) {
          this.deleteOcInvoice()
          this.ownersContribution = this.innerValue.ownersContribution
          this.invoice.extraCharge = this.innerValue.extraCharge
          this.invoice.ownerContribution = this.ownersContribution
          this.invoice.extraCharge = this.extraCharge
          this.invoice.totalExGst = this.invoice.excessAmount + this.invoice.ownerContribution + this.invoice.extraCharge
          this.invoice.gst = roundAwayFromZero(((this.invoice.excessAmount + this.invoice.ownerContribution) * this.innerValue.gstRate) / 100)
        } else {
          if (this.ownersContribution > 0 || this.extraCharge > 0) {
            this.addOcInvoice(this.ownersContribution, this.extraCharge)
            this.invoice.gst = 0
          }
        }
        this.innerValue.excessWithGst = this.excessWithGst
        this.getInvoice()
      }
    },
    updateInvoice() {
      // this.invoice.excessWithGst = this.innerValue.excessWithGst
      if (this.invoice) {
        this.invoice.excessAmount = this.innerValue.excess
        this.invoice.ownerContribution = this.innerValue.ownersContribution
      }
      if (this.ocInvoice) {
        this.ownersContribution = this.innerValue.ownersContribution
        this.ocInvoice.ownerContribution = this.innerValue.ownersContribution
      }
      this.recalculateTotals()
    },
    cancelInvoice() {
      // this.isUpdateExcessInvoice = false
      this.setIsUpdateExcessInvoice(false)
      this.getInvoice(true)
    },
    async addInvoice() {
      // this.isUpdateExcessInvoice = true
      this.setIsUpdateExcessInvoice(true)
      this.isLoadingInvoice = true
      this.invoice = await QuoteInvoiceService.getNewEntity()
      this.existingInvoice = this.innerValue.invoices.find((i) => !i.isNew && !i.deleted)
      let invoiceNo = this.invoice.invoiceNo
      if (this.existingInvoice) {
        invoiceNo = this.existingInvoice.invoiceNo
      }

      // New excess invoice values
      this.invoice.invoiceNo = invoiceNo
      this.excessWithGst = this.innerValue.excessWithGst
      this.invoice.gstRate = this.innerValue.gstRate
      this.invoice.excessAmount = this.innerValue.excess
      this.invoice.ownerContribution = this.innerValue.ownersContribution
      this.recalculateTotals()
      this.invoice.invoiceType = InvoiceTypes.Excess
      this.invoice.invoicePayingType = InvoicePayerTypes.ThirdParty
      this.invoice.assetType = AssetTypes.Customer
      this.invoice.assetId = this.innerValue.customerId
      this.selectedInvoiceDate = new Date() // new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
      this.invoice.includedQuotes.splice(this.invoice.includedQuotes.length, 1, this.innerValue.quoteId)
      this.innerValue.invoices.splice(this.innerValue.invoices.length, 1, this.invoice)

      await this.addOcInvoice(this.innerValue.ownersContribution, 0)
      // New owner contribution invoice values
      // if (!this.innerValue.excessWithGst) {
      //   this.ocInvoice = await QuoteInvoiceService.getNewEntity()
      //   this.ocInvoice.invoiceNo = invoiceNo
      //   this.ocInvoice.excessWithGst = this.innerValue.excessWithGst
      //   this.ocInvoice.gstRate = this.innerValue.gstRate
      //   this.ocInvoice.ownerContribution = this.innerValue.ownersContribution
      //   this.ocInvoice.totalExGst = this.ocInvoice.ownerContribution + this.ocInvoice.extraCharge
      //   this.ocInvoice.gst = roundAwayFromZero((this.ocInvoice.totalExGst * this.ocInvoice.gstRate) / 100)
      //   this.ocInvoice.totalIncGst = this.ocInvoice.totalExGst + this.ocInvoice.gst
      //   this.ocInvoice.invoiceType = InvoiceTypes.OwnerContribution
      //   this.ocInvoice.invoicePayingType = InvoicePayerTypes.ThirdParty
      //   this.ocInvoice.assetType = AssetTypes.Customer
      //   this.ocInvoice.assetId = this.innerValue.customerId
      //   this.ocInvoice.includedQuotes.splice(this.invoice.includedQuotes.length, 1, this.innerValue.quoteId)
      //   this.innerValue.invoices.splice(this.innerValue.invoices.length, 1, this.ocInvoice)
      // } else {
      //   this.ocInvoice = null
      // }

      this.isLoadingInvoice = false
    },
    async addOcInvoice(ownersContribution, extraCharge) {
      // New owner contribution invoice values
      // console.log('addOcInvoice', ownersContribution, extraCharge, !this.excessWithGst && (ownersContribution > 0 || extraCharge > 0))
      if (!this.excessWithGst && (ownersContribution > 0 || extraCharge > 0)) {
        if (!this.ocInvoice) {
          this.existingInvoice = this.innerValue.invoices.find((i) => !i.deleted)
          let invoiceNo = this.invoice.invoiceNo
          if (this.existingInvoice) {
            invoiceNo = this.existingInvoice.invoiceNo
          }
          this.ocInvoice = await QuoteInvoiceService.getNewEntity()
          this.ocInvoice.invoiceDate = this.invoice.invoiceDate
          this.ocInvoice.invoiceNo = invoiceNo
          this.ocInvoice.excessWithGst = this.innerValue.excessWithGst
          this.ocInvoice.gstRate = this.innerValue.gstRate
          // this.ocInvoice.ownerContribution = ownersContribution // this.innerValue.ownersContribution
          // this.ocInvoice.extraCharge = extraCharge
          // this.ocInvoice.totalExGst = this.ocInvoice.ownerContribution + this.ocInvoice.extraCharge
          // this.ocInvoice.gst = roundAwayFromZero((this.ocInvoice.totalExGst * this.ocInvoice.gstRate) / 100)
          // this.ocInvoice.totalIncGst = this.ocInvoice.totalExGst + this.ocInvoice.gst
          this.ocInvoice.invoiceType = InvoiceTypes.OwnerContribution
          this.ocInvoice.invoicePayingType = InvoicePayerTypes.ThirdParty
          this.ocInvoice.assetType = AssetTypes.Customer
          this.ocInvoice.assetId = this.innerValue.customerId
          this.ocInvoice.includedQuotes.splice(this.invoice.includedQuotes.length, 1, this.innerValue.quoteId)
          this.innerValue.invoices.splice(this.innerValue.invoices.length, 1, this.ocInvoice)
        } // else if (this.baseOcInvoice) {
        //   this.ocInvoice = this.innerValue.invoices.find(i => i.invoiceId === this.baseOcInvoice.invoiceId)
        //   this.ocInvoice.deleted = false
        //   this.ocInvoice.isDeleted = false
        // }
        this.ocInvoice.ownerContribution = ownersContribution
        this.ocInvoice.extraCharge = extraCharge
        this.ocInvoice.totalExGst = this.ocInvoice.ownerContribution + this.ocInvoice.extraCharge
        this.ocInvoice.gst = roundAwayFromZero((this.ocInvoice.totalExGst * this.ocInvoice.gstRate) / 100)
        this.ocInvoice.totalIncGst = this.ocInvoice.totalExGst + this.ocInvoice.gst
        this.invoice.ownerContribution = 0
        this.invoice.extraCharge = 0
        this.invoice.gst = 0
      } else {
        this.ocInvoice = null
      }
    },
    deleteInvoice(invoiceType) {
      if (this.invoice && invoiceType === InvoiceTypes.Excess) {
        if (this.invoice.isNew) {
          const index = this.innerValue.invoices
            .map(function (obj) {
              return obj.invoiceId
            })
            .indexOf(this.invoice.invoiceId)
          this.innerValue.invoices.splice(index, 1)
          this.invoice = null
        } else {
          this.invoice.deleted = true
          this.invoice.isDeleted = true
        }
      }
      if (this.ocInvoice && invoiceType === InvoiceTypes.OwnerContribution) this.deleteOcInvoice()
      // if (this.ocInvoice) {
      //   if (this.ocInvoice.isNew) {
      //     const index = this.innerValue.invoices
      //       .map(function(obj) {
      //         return obj.quoteItemId
      //       })
      //       .indexOf(this.ocInvoice.invoiceId)
      //     this.innerValue.invoices.splice(index, 1)
      //     this.ocInvoice = null
      //   } else {
      //     this.ocInvoice.deleted = true
      //     this.ocInvoice.isDeleted = true
      //   }
      // }
      this.getInvoice(false, true)
      this.setIsUpdateExcessInvoice(false)
    },
    deleteOcInvoice() {
      if (this.ocInvoice) {
        // console.log('deleteOcInvoice')
        if (this.ocInvoice.isNew) {
          const index = this.innerValue.invoices
            .map(function (obj) {
              return obj.invoiceId
            })
            .indexOf(this.ocInvoice.invoiceId)
          this.innerValue.invoices.splice(index, 1)
          this.ocInvoice = null
        } else {
          this.ocInvoice.deleted = true
          this.ocInvoice.isDeleted = true
          this.ownersContribution = 0
          this.extraCharge = 0
        }
        if (this.invoice) {
          this.invoice.ownerContribution = this.ownersContribution
          this.invoice.extraCharge = this.extraCharge
        }
      }
    },
    updateTotal(updateGst = true) {
      // if (this.isUpdateExcessInvoice) {
      //   // this.updateInvoice()
      //   const totalExGst = this.invoice.excessAmount + this.invoice.ownerContribution + this.invoice.extraCharge
      //   if (updateGst) {
      //     if (this.innerValue.excessWithGst) {
      //       this.invoice.gst = roundAwayFromZero((totalExGst * this.innerValue.gstRate) / 100)
      //     } else {
      //       this.invoice.gst = 0
      //     }
      //   }
      //   this.invoice.totalExGst = totalExGst
      //   this.invoice.totalIncGst = this.invoice.totalExGst + this.invoice.gst
      // }
    },
    recalculateTotals(reset = true) {
      if (this.invoice) {
        if (!this.innerValue.excessWithGst) {
          this.invoice.totalExGst = this.invoice.excessAmount
          if (reset) {
            this.invoice.gst = 0
          }
        } else {
          this.invoice.totalExGst = this.invoice.excessAmount + this.invoice.ownerContribution + this.invoice.extraCharge
          if (reset) {
            this.invoice.gst = roundAwayFromZero((this.invoice.totalExGst * this.invoice.gstRate) / 100)
          }
        }
        this.invoice.totalIncGst = this.invoice.totalExGst + this.invoice.gst
      }
      if (this.ocInvoice) {
        this.ocInvoice.totalExGst = this.ocInvoice.ownerContribution + this.ocInvoice.extraCharge
        if (reset) {
          this.ocInvoice.gst = roundAwayFromZero((this.ocInvoice.totalExGst * this.ocInvoice.gstRate) / 100)
        }
        this.ocInvoice.totalIncGst = this.ocInvoice.totalExGst + this.ocInvoice.gst
      }
    },
    reloadInvoice() {
      // console.log('reloadInvoice')
      this.innerValue = this.value
      this.getInvoice()
    },
    onBlurOwnersContribution() {
      this.innerValue.ownersContribution = this.ownersContribution
      if (!this.excessWithGst) {
        if (this.ownersContribution > 0 || this.extraCharge > 0) {
          this.addOcInvoice(this.ownersContribution, this.extraCharge)
        } else {
          this.deleteOcInvoice()
        }
      } else {
        this.invoice.ownerContribution = this.ownersContribution
      }
    },
    onBlurExtraCharge() {
      if (!this.excessWithGst) {
        if (this.ownersContribution > 0 || this.extraCharge > 0) {
          this.addOcInvoice(this.ownersContribution, this.extraCharge)
        } else {
          this.deleteOcInvoice()
        }
      } else {
        this.invoice.extraCharge = this.extraCharge
      }
    },
    invoiceDateInputEvent(event) {
      this.selectedInvoiceDate = this.$filters.fromISOWithCurrentTime(event.toISOString())
    }
  }
}
</script>

<style lang="scss" scoped>
.is-divider {
  margin: 1.2em 0;
}
</style>
